<template>
  <div id="fixed-right">
    <button
      v-if="isButtonVisible"
      id="popover-button-1"
      class="chat-btn"
      @click="showPopover"
    >
      <feather-icon
        icon="MessageSquareIcon"
        size="20"
      />
      {{ $t('contact') }}
    </button>
    <button
      v-if="isButtonVisible"
      class="btn btn-danger btn-sm btn-close"
      @click="hideButtonChat"
    >
      x
    </button>
    <b-popover
      target="popover-button-1"
      placement="top"
      triggers="click"
    >
      <template #title>
        {{ $t('key-106') }}
      </template>
      <div class="text-center">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                size="34"
                :src="chatData.contact.avatar"
                class="mr-50 badge-minimal"
                badge
                badge-variant="success"
              />
              <h5 class="mb-0">
                <!-- P2 Cargo -->
                {{ chatData.contact.fullName }}
              </h5>
            </div>

          </b-card-header>

          <section class="chat-app-window">
            <!-- User Chat Area -->
            <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
            >
              <chat-log
                :chat-data="chatData"
                :profile-user-avatar="require('@/assets/images/avatars/1.png')"
              />
            </vue-perfect-scrollbar>
            <div
              v-if="filechats"
              class="mt-1 d-flex justify-content-left ml-1 table-container"
            >
              <span
                v-for="imageOr in filechat"
                :key="imageOr"
                class="preview-image-containerv2 mr-1 d-flex justify-content-center"
              >
                <img
                  :src="imageOr"
                  alt="Preview"
                  class="p-2"
                >
                <div class="preview-image-overlays p-0">
                  <i
                    class="fas fa-times-circle cursor-pointer"
                    @click="deleteImageorder(imageOr)"
                  />
                </div>
              </span>
            </div>
            <!-- Message Input -->

            <b-form
              class="chat-app-form"
              @submit.prevent="sendMessage"
            >

              <div class="input-file-container ">
                <feather-icon
                  v-model="filechats"
                  icon="CameraIcon"
                  size="15"
                />
                <input
                  type="file"
                  class="input-file"
                  accept="image/*"
                  multiple
                  @change="fileImage"
                >
              </div>

              <!-- <div class="input-file-container mr-50">
                <feather-icon
                  v-model="filechats"
                  icon="PaperclipIcon"
                  size="15"
                />
                <input
                  type="file"
                  class="input-file"
                  accept="image/*"
                  multiple
                  @change="fileItem"
                >
              </div> -->

              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-input
                  v-model="chatInputMessage"
                  :placeholder="$t('key-107')"
                />
              </b-input-group>
              <b-button
                variant="primary"
                type="submit"
              >
                Send
              </b-button>
            </b-form>
          </section>
        </b-card>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  VBPopover,
  BPopover,
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from '@/views/customer/chat/ChatLog.vue'
import moment from 'moment-timezone'

export default {
  name: 'Chat',
  components: {
    BPopover,
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    ChatLog,
    VuePerfectScrollbar,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      show: false,
      disabled: false,
      filechat: [],
      filechats: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      chatInputMessage: null,
      chatData: {
        chat: {
          id: 2,
          userId: 1,
          unseenMsgs: 0,
          chat: [
            // {
            // message: 'สวัสดี',

            // senderId: 1,
            // },

          ],
        },
        contact: {
          id: 75,
          fullName: 'P2 Cargo',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/logo/logo-p2.webp'),
          status: 'away',
        },
      },
      UserData: JSON.parse(localStorage.getItem('userData')),
      WsChatfrist: null,
      autochat: false,
      isButtonVisible: true,
      Datageneral: null,
    }
  },
  computed: {
    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsMain() {
      return this.$store.state.app.WsMain
    },
    WsChat() {
      return this.$store.state.app.WsChat
    },
  },
  mounted() {
    if (this.UserData) {
      // this.getUserData()
      this.CheckWsConnect()
      // this.psToBottom()
      this.General()
    }
    this.GetData()
    // this.CheckWsConnect()
  },
  methods: {
    async GetData() {
      try {
        const { data: res } = await this.$http.get('/Chat/getDataCus')
        this.chatData.chat.chat = res
      } catch (err) {
        console.log(err)
      }
    },
    async General() {
      try {
        const { data: res } = await this.$http.get('general/getData')
        if (res.status) {
          this.Datageneral = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    hideButtonChat() {
      this.isButtonVisible = false
    },
    async CheckWsConnect() {
      if (!this.WsMain) {
        await this.ConnectWS()
      }
      if (!this.WsChat) {
        await this.SubWsOnChat()
        this.WsOnChat()
      } else {
        this.WsOnChat()
      }
    },
    SubWsOnChat() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsChat = this.WsMain.subscribe(`chat:${UserData.userID}`)
      // this.WsChatfrist = this.WsMain.subscribe(`fristchat:${UserData.agent_id}`)
      this.$store.commit('app/SET_WSCHAT', WsChat)

      // this.WsChatfrist.emit('message', msg)
    },
    // async getUserData() {
    //   try {
    //     const { data: res } = await this.$http.get('/Chat/getDataAmin')
    //     this.chatData.contact = res.data
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    WsOnChat() {
      this.WsChat.on('open', () => {
        console.log('WsOnChat established')
      })
      // this.WsChat.emit('cart', '')
      this.WsChat.on('message', message => {
        // eslint-disable-next-line no-param-reassign
        message.time = this.time(message.time)
        this.chatData.chat.chat.push(message)
        this.$nextTick(() => {
          this.psToBottom()
        })
      })
      this.WsChat.on('error', error => {
        console.log('WsOnChat Error', error)
      })
    },
    // Input Images
    sendMessage() {
      // console.log(this.filechats)
      if (this.chatInputMessage === null && this.filechats === null) { return }
      // console.log(this.filechats)

      // this.filechats = [] // File Image
      // this.filechat = ''// Image Base64
      const msg = {
        msgType: this.chatInputMessage ? 'text' : 'image',
        message: this.chatInputMessage,
        time: this.time(new Date()),
        senderId: this.UserData.userID,
        agent_id: this.UserData.agent_id,
        unseenMsgs: false,
        fileimgcheck: false,
      }

      // console.log(msg)
      // console.log(msg)
      if (this.filechat.length) {
        msg.fileimg = this.filechat
        msg.fileimgcheck = true
        // this.chatData.chat.chat.push({
        //   img: this.filechat,
        //   time: this.time(new Date()),
        //   senderId: this.UserData.userID,
        // })
      }
      this.WsChat.emit('message', msg)

      // this.WsChatfrist.emit('message', msg)
      this.chatInputMessage = null
      this.filechats = []
      this.filechat = []
      // console.log(this.chatData)
      // this.$nextTick(() => {
      //   this.psToBottom()
      // })
      // if (this.chatInputMessage === '') {

      // }
      // console.log(this.chatData.chat.chat)

      // console.log(this.chatData)
    },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // Input Images
    fileImage(event) {
      this.filechats = event.target.files
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.filechats.length; i++) {
        const filepath = this.filechats[i]

        const reader = new FileReader()
        reader.onload = e => {
          this.filechat.push(e.target.result)
        }
        reader.readAsDataURL(filepath)
      }
    },
    fileItem() {
      console.debug('kk')
    },
    deleteImageorder(index) {
      const indexToRemove = this.filechat.indexOf(index)
      if (indexToRemove !== -1) {
        this.filechat.splice(indexToRemove, 1)
      }
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    showPopover() {
      if (!this.autochat) {
        const msg = {
          msgType: 'text',
          message: this.Datageneral.chat_auto,
          // time: this.time(new Date()),
          senderId: this.chatData.contact.id,
          agent_id: this.UserData.agent_id,
          autochat: true,
        }
        this.WsChat.emit('message', msg)
      }
      this.autochat = true
      if (this.chatData.chat.chat.length) {
        this.$nextTick(() => {
          this.psToBottom()
        })
      }

      this.$root.$emit('bv::show::popover', 'popover-button-1')
    },
    hidePopover() {
      this.$root.$emit('bv::hide::popover', 'popover-button-2')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
.chat-widget {
  width: 320px;
}

.popover{
  max-width:340px;
}

.btn-close{
  position: absolute;
  border-radius: 100%;
  padding: 2px 5px;
  right: -2px;
  top: 0px;
  z-index: 99 !important;
}

</style>
