<template>
  <div>
    <!-- ON MOBILE -->
    <div>
      <div v-b-toggle.sidebar-1 class="p-0 pa-0">
        <feather-icon icon="MenuIcon" size="22" />
      </div>

      <b-sidebar
        id="sidebar-1"
        shadow
        bg-variant="white"
        backdrop
        footer
        class="z-show"
      >
        <b-form-group>
          <div class="text-center">
            <p class="mb-0 small">{{ $t("greeting") }}, {{ items.fullname }}</p>
            <b-dropdown no-caret variant="outline-dark" size="sm" class="mt-50">
              <template #button-content>
                <span class="text-dark"
                  ><i class="far fa-globe-americas" />
                  {{ selectedLang || "ไทย" }}</span
                >
              </template>
              <b-dropdown-item
                value="th"
                @click="updateSelectedText('ไทย', 'th')"
                ><img src="/thai.png" class="flag-icon" /> ไทย</b-dropdown-item
              >
              <b-dropdown-item
                value="en"
                @click="updateSelectedText('English', 'en')"
                ><img src="/eng.png" class="flag-icon" />
                English</b-dropdown-item
              >
              <b-dropdown-item
                value="cn"
                @click="updateSelectedText('中国', 'cn')"
                ><img src="/china.png" class="flag-icon" />
                中国</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <ul class="menu mt-1">
            <div v-for="menuCategory in MobileMenu" :key="menuCategory">
              <li v-if="!menuCategory.title" :key="menuCategory.label">
                <router-link
                  v-for="item in menuCategory.items"
                  :key="item.label"
                  :to="{ name: item.link }"
                  class="w-100"
                >
                  <b-button :variant="item.Active" block class="text-left">
                    <feather-icon :icon="item.icon" class="mr-1" />
                    {{ item.label }}
                  </b-button>
                </router-link>
              </li>
            </div>
          </ul>

          <app-collapse accordion type="margin">
            <div v-for="menuCategory in MobileMenu" :key="menuCategory">
              <app-collapse-item
                v-if="menuCategory.title"
                :key="menuCategory.title"
                :title="menuCategory.title"
              >
                <ul class="menu">
                  <li v-for="item in menuCategory.items" :key="item.label">
                    <router-link :to="{ name: item.link }" class="w-100">
                      <b-button :variant="item.Active" block class="text-left">
                        <feather-icon :icon="item.icon" class="mr-1" />
                        {{ item.label }}
                      </b-button>
                    </router-link>
                  </li>
                </ul>
              </app-collapse-item>
            </div>
          </app-collapse>
          <!-- <ul class="menu mt-1">
            <li>
              <b-button
                :to="{ name: 'tracking' }"
                block
                class="text-left menu-hover"
              >
                <feather-icon
                  icon="LogOutIcon"
                  class="mr-1"
                />
                {{ $t("parcelCode") }}
              </b-button>
            </li>
            <li>
              <b-button
                :to="{ name: 'payment-create' }"
                block
                class="text-left menu-hover"
              >
                <feather-icon
                  icon="LogOutIcon"
                  class="mr-1"
                />
                {{ $t("checkAndNotify") }}
              </b-button>
            </li>
          </ul>
          <hr> -->
          <ul class="menu">
            <li v-if="UserData.role === 'admin'">
              <b-link
                class="mt-1 w-100"
                :to="{
                  name: `${
                    UserData.ability.find((item) =>
                      item.subject.startsWith('admin')
                    ).subject
                  }`,
                }"
              >
                <b-button variant="gradient-success" block class="text-left">
                  <feather-icon icon="SettingsIcon" class="mr-1" />
                  Admin Center
                </b-button>
              </b-link>
            </li>
            <li>
              <b-link
                class="mt-1 w-100"
                href="https://p2cargo.com/manual/profile"
                target="_blank"
                @click="navigateToLink"
              >
                <b-button variant="gradient-primary" block class="text-left">
                  <feather-icon icon="HelpCircleIcon" class="mr-1" />
                  คู่มือใช้งาน
                </b-button>
              </b-link>
            </li>
            <li>
              <b-link class="mt-1 w-100" @click="logout()">
                <b-button
                  variant="gradient-danger"
                  block
                  class="text-left mb-1"
                >
                  <feather-icon icon="LogOutIcon" class="mr-1" />
                  {{ $t("logout") }}
                </b-button>
              </b-link>
            </li>
          </ul>
        </b-form-group>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "SideBar",
  components: {
    BLink,
    BButton,
    BSidebar,
    AppCollapse,
    AppCollapseItem,
    BDropdown,
    BDropdownItem,
    // AppCollapseItemMargin,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      // Loader
      items: null,
      locale: this.$i18n.locale,
      selectedLang: "",
      UserData: JSON.parse(localStorage.getItem("userData")),
      loading: true,
    };
  },
  computed: {
    MobileMenu() {
      const items = [];
      items.push({
        items: [
          {
            Active: this.$route.name === "new-pages" ? "gradient-primary" : "",
            icon: "HomeIcon",
            label: this.$t("key-176"),
            link: "new-pages",
          },
        ],
      });
      if (this.UserData.ability.some((ele) => ele.subject === "order-list")) {
        items.push({
          items: [
            {
              Active:
                this.$route.name === "order-list" ? "gradient-primary" : "",
              icon: "ShoppingCartIcon",
              label: this.$t("orderList"),
              link: "order-list",
            },
          ],
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "tracking")) {
        items.push({
          items: [
            {
              Active: this.$route.name === "tracking" ? "gradient-primary" : "",
              icon: "BoxIcon",
              label: this.$t("parcelCode"),
              link: "tracking",
            },
          ],
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "qc-postnumber")) {
        items.push({
          items: [
            {
              Active: this.$route.name === "QC-user" ? "gradient-primary" : "",
              icon: "CheckCircleIcon",
              label: 'ยืนยัน QC',
              link: "QC-user",
            },
          ],
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "checkbill")) {
        items.push({
          items: [
            {
              Active:
                this.$route.name === "payment-create" ? "gradient-primary" : "",
              icon: "ArchiveIcon",
              label: this.$t("checkAndNotify"),
              link: "payment-create",
            },
          ],
        });
      }
      if (
        this.UserData.ability.some((ele) => ele.subject === "exportproducts")
      ) {
        items.push({
          items: [
            {
              Active: this.$route.name === "export" ? "gradient-primary" : "",
              icon: "SendIcon",
              label: this.$t("exportList"),
              link: "export",
            },
          ],
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "lost_package")) {
        items.push({
          items: [
            {
              Active:
                this.$route.name === "lost-package" ? "gradient-primary" : "",
              icon: "DeleteIcon",
              label: this.$t("reportMissingGoods"),
              link: "lost-package",
            },
          ],
        });
      }

      if (this.UserData.ability.some((ele) => ele.subject === "goodsconfirm")) {
        items.push({
          items: [
            {
              Active:
                this.$route.name === "goodsconfirm" ? "gradient-primary" : "",
              icon: "UserXIcon",
              label: this.$t("ownerlessGoods"),
              link: "goodsconfirm",
            },
          ],
        });
      }

      if (this.UserData.ability.some((ele) => ele.subject === "point")) {
        items.push({
          items: [
            {
              Active: this.$route.name === "point" ? "gradient-primary" : "",
              icon: "StarIcon",
              label: this.$t("points"),
              link: "point",
            },
          ],
        });
      }

      if (this.UserData.ability.some((ele) => ele.subject === "notify")) {
        items.push({
          items: [
            {
              Active: this.$route.name === "notice" ? "gradient-primary" : "",
              icon: "AlertCircleIcon",
              label: this.$t("announcement"),
              link: "notice",
            },
          ],
        });
      }

      if (this.UserData.ability.some((ele) => ele.subject === "line_notify")) {
        items.push({
          items: [
            {
              Active:
                this.$route.name === "line-notify" ? "gradient-primary" : "",
              icon: "PlayIcon",
              label: `${this.$t("key-147")} LINE Notify`,
              link: "line-notify",
            },
          ],
        });
      }

      if (this.UserData.ability.some((ele) => ele.subject === "questions")) {
        items.push({
          items: [
            {
              Active: this.$route.name === "faq" ? "gradient-primary" : "",
              icon: "HelpCircleIcon",
              label: this.$t("faq"),
              link: "faq",
            },
          ],
        });
      }

      items.push({
        title: this.$t("manageMyAccount"),
        icon: "ChevronRightIcon",
        items: [
          {
            Active: this.$route.name === "profile" ? "gradient-primary" : "",
            icon: "ChevronRightIcon",
            label: this.$t("key-91"),
            link: "profile",
          },
          {
            Active: this.$route.name === "my-profile" ? "gradient-primary" : "",
            icon: "ChevronRightIcon",
            label: this.$t("personalInfo"),
            link: "my-profile",
          },
          {
            Active:
              this.$route.name === "profile-address" ? "gradient-primary" : "",
            icon: "ChevronRightIcon",
            label: this.$t("addressBook"),
            link: "profile-address",
          },
        ],
      });
      // items.push({
      //   title: this.$t('key-323'),
      //   icon: 'ChevronRightIcon',
      //   items: [
      //     {
      //       Active: this.$route.name === 'member-user' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('user'),
      //       link: 'member-user',
      //     },
      //     {
      //       Active: this.$route.name === 'member-parcelcode' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('parcelCode'),
      //       link: 'member-parcelcode',
      //     },
      //   ],
      // })
      const newitem2 = {
        title: this.$t("key-323"),
        icon: "ChevronRightIcon",
        items: [],
      };
      if (this.UserData.ability.some((ele) => ele.subject === "agent_user")) {
        newitem2.items.push({
          Active: this.$route.name === "member-user" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("user"),
          link: "member-user",
        });
      }

      if (
        this.UserData.ability.some((ele) => ele.subject === "agent_tracking")
      ) {
        newitem2.items.push({
          Active:
            this.$route.name === "member-parcelcode" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("parcelCode"),
          link: "member-parcelcode",
        });
      }

      const newitem = {
        title: this.$t("myFinances"),
        icon: "ChevronRightIcon",
        items: [],
      };
      if (this.UserData.ability.some((ele) => ele.subject === "refill")) {
        newitem.items.push({
          Active:
            this.$route.name === "customer-refill" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("topupSystem"),
          link: "customer-refill",
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "transfer")) {
        newitem.items.push({
          Active:
            this.$route.name === "transfer-list" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("transferToChina"),
          link: "transfer-list",
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "alipay")) {
        newitem.items.push({
          Active: this.$route.name === "alipay-list" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("topupAlipay"),
          link: "alipay-list",
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "payfor")) {
        newitem.items.push({
          Active: this.$route.name === "payfor-list" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("DepositTaobao"),
          link: "payfor-list",
        });
      }
      if (this.UserData.ability.some((ele) => ele.subject === "balance")) {
        newitem.items.push({
          Active: this.$route.name === "balance" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("ledger"),
          link: "balance",
        });
      }
      if (
        this.UserData.ability.some((ele) => ele.subject === "withdraw_money")
      ) {
        newitem.items.push({
          Active: this.$route.name === "withdraw" ? "gradient-primary" : "",
          icon: "ChevronRightIcon",
          label: this.$t("withdraw"),
          link: "withdraw",
        });
      }
      items.push(newitem);
      if (
        this.UserData.ability.some(
          (ele) =>
            ele.subject === "agent_user" || ele.subject === "agent_tracking"
        )
      ) {
        items.push(newitem2);
      }

      return items;
      // return [
      // {
      //   items: [{
      //     Active: this.$route.name === 'new-pages' ? 'gradient-primary' : '',
      //     icon: 'HomeIcon',
      //     label: this.$t('key-176'),
      //     link: 'new-pages',
      //   },
      //   ],
      // },
      // {
      //   items: [{
      //     Active: this.$route.name === 'order-list' ? 'gradient-primary' : '',
      //     icon: 'ShoppingCartIcon',
      //     label: this.$t('orderList'),
      //     link: 'order-list',
      //   },
      //   ],
      // },
      // {
      //   items: [{
      //     Active: this.$route.name === 'payment-create' ? 'gradient-primary' : '',
      //     icon: 'ArchiveIcon',
      //     label: this.$t('checkStatus'),
      //     link: 'payment-create',
      //   }],
      // },
      // {
      //   items: [{
      //     Active: this.$route.name === 'export' ? 'gradient-primary' : '',
      //     icon: 'SendIcon',
      //     label: this.$t('exportRecords'),
      //     link: 'export',
      //   }],
      // },
      // {
      //   items: [{
      //     Active: this.$route.name === 'tracking' ? 'gradient-primary' : '',
      //     icon: 'BoxIcon',
      //     label: this.$t('parcelCode'),
      //     link: 'tracking',
      //   }],
      // },
      // {
      //   items: [{
      //     Active: this.$route.name === 'goodsconfirm' ? 'gradient-primary' : '',
      //     icon: 'DeleteIcon',
      //     label: this.$t('ownerlessGoods'),
      //     link: 'goodsconfirm',
      //   }],
      // },
      // {
      //   title: this.$t('manageMyAccount'),
      //   icon: 'ChevronRightIcon',
      //   items: [
      //     {
      //       Active: this.$route.name === 'profile' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: 'บัญชีของฉัน',
      //       link: 'profile',
      //     },
      //     {
      //       Active: this.$route.name === 'my-profile' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('personalInfo'),
      //       link: 'my-profile',
      //     },
      //     {
      //       Active: this.$route.name === 'profile-address' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('addressBook'),
      //       link: 'profile-address',
      //     },
      //   ],
      // },
      // {
      //   title: this.$t('myFinances'),
      //   icon: 'ChevronRightIcon',
      //   items: [
      //     {
      //       Active: this.$route.name === 'customer-refill' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('topupSystem'),
      //       link: 'customer-refill',
      //     },
      //     {
      //       Active: this.$route.name === 'transfer-list' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('transferToChina'),
      //       link: 'transfer-list',
      //     },
      //     {
      //       Active: this.$route.name === 'alipay-list' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('topupAlipay'),
      //       link: 'alipay-list',
      //     },
      //     {
      //       Active: this.$route.name === 'payfor-list' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('DepositTaobao'),
      //       link: 'payfor-list',
      //     },
      //     {
      //       Active: this.$route.name === 'balance' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('ledger'),
      //       link: 'balance',
      //     },
      //     {
      //       Active: this.$route.name === 'withdraw' ? 'gradient-primary' : '',
      //       icon: 'ChevronRightIcon',
      //       label: this.$t('withdraw'),
      //       link: 'withdraw',
      //     },
      //   ],
      // },
      // ]
    },
  },
  mounted() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },

  methods: {
    navigateToLink(event) {
      event.preventDefault();
      window.open(event.currentTarget.href, "_blank");
    },
    updateSelectedText(text, lang) {
      this.selectedLang = text;
      this.$i18n.locale = lang;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });

      this.onShow = localStorage.getItem("token") !== null;
    },
    async getData() {
      try {
        const { data: res } = await this.$http.get("/Profilecustomer/show");
        this.items = res;
        // res.forEach(ele => {
        //   this.items.id = ele.id
        //   this.items.email = ele.email
        //   this.items.fullname = ele.fullname
        //   this.items.line_id = ele.line_id
        //   this.items.tel = ele.tel
        //   this.items.username = ele.username
        // })
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  list-style-type: none;
  padding: 0;
}

.menu li {
  padding: 3px 16px;
  display: flex;
  align-items: center;
}

.menu-hover:hover {
  color: white !important;
}
</style>

<style scoped>
.z-show {
  transform: translateZ(0);
  z-index: 101;
}
</style>