<template>
  <nav class="nav fixed-bottom-nav">
    <router-link
      to="/new-pages"
      class="nav-item"
      :class="{ 'is-active': this.$route.name === 'new-pages' }"
    >
      <feather-icon
        icon="HomeIcon"
        size="25"
      />
      <span>หน้าแรก</span>
    </router-link>
    <router-link
      to="/order-list"
      class="nav-item"
      :class="{ 'is-active': this.$route.name === 'order-list' }"
    >
      <feather-icon
        icon="ShoppingCartIcon"
        size="25"
      />
      <span>รายการสั่งซื้อ</span>
    </router-link>
    <router-link
      to="/payment/create"
      class="nav-item"
      :class="{ 'is-active': this.$route.name === 'payment-create' }"
    >
      <feather-icon
        icon="ArchiveIcon"
        size="25"
      />
      <span>เช็คสถานะ</span>
    </router-link>
    <router-link
      to="/account/profile"
      class="nav-item"
      :class="{ 'is-active': this.$route.name === 'profile' }"
    >
      <feather-icon
        icon="UserIcon"
        size="25"
      />
      <span>บัญชีของฉัน</span>
    </router-link>
    <a
      href="#"
      class="nav-item"
      :class="{ 'is-active': activeIndex === 2 }"
      @click.prevent="setActive(2, $event)"
    >
      <MenuMobile />
      <span>เมนู</span>
    </a>
  </nav>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import MenuMobile from './MenuMobile.vue'

export default {
  components: { FeatherIcon, MenuMobile },
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {},
}
</script>
<style scoped>
.fixed-bottom-nav {
  position: fixed;
  z-index: 99 !important;
  bottom: 0;
  background-color: #f8f9fa;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
}

.nav {
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  background-color: #ffffff;
  /* border: 1px solid #252325; */
  /* border-radius: 30px; */
  overflow: hidden;
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 20%;
  /* margin: 0 5px; */
  padding: 5px;
  color: #a0a5ab !important;
  text-decoration: none;
  transition: 0.3s;
}

.nav-item.is-active {
  color: #05814e !important;
}

.nav-item span {
  font-size: 11px;
  font-weight: 600;
}
</style>
