<template>
  <div>
    <header class="anan-top--sticky anan-top">
      <div class="anan-set-nav-conttainer d-none d-md-block">
        <div class="d-flex justify-content-between align-items-center">
          <div class="anan-set-nav-title">
            <div class="flex">
              <div
                v-if="UserData.role === 'admin'"
                class="anan-drawer ZUq1cc"
              >
                <b-link
                  :to="{ name: `${UserData.ability.find(item => item.subject.startsWith('admin')).subject}` }"
                  class="flex _2TLLZP ZUq1cc"
                >
                  <span>Admin Center</span>
                </b-link>
              </div>

              <div class="anan-drawer ZUq1cc">
                <!-- <b-link class="flex _2TLLZP ZUq1cc"> -->
                <span class="text-white mt-25 ml-25">{{ $t("followUs") }}
                  <a href="anancargo2022@gmail.com"><i class="fas fa-envelope mr-50 ml-25 text-white" /></a>
                  <a href="https://www.facebook.com/ANANCARGO1"><i class="fab fa-facebook mr-50 text-white" /></a>
                  <a href="https://lin.ee/S8q5MCkR"><i class="fab fa-line mr-50 text-white" /></a>
                  <a href="www.tiktok.com/@anan_cargo"><i class="fab fa-tiktok mr-50 text-white" /></a>
                </span>
                <!-- </b-link> -->
              </div>
            </div>
          </div>

          <div class="anan-set-nav-title">
            <div class="flex align-items-center">
              <div class="anan-drawer ZUq1cc">
                <b-link class="flex _2TLLZP ZUq1cc">
                  <span id="popover-target-1"><i class="fal fa-bell" /> {{ $t("notifications") }}
                    <feather-icon
                      :badge="SendNoticount"
                      badge-classes="bg-danger"
                      class="text-body"
                      size="21"
                    />
                  </span>
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="bottom"
                    hide-header
                  >
                    <div class="d-flex justify-content-between p-1">
                      <p class="m-0 mr-4">
                        {{ $t("key-89") }}
                      </p>
                      <p class="m-0">
                        {{ SendNoticountall }} {{ $t("item") }}
                      </p>
                    </div>

                    <div
                      v-if="SendNotiData.length"
                      class="list-profile border-top"
                    >
                      <b-list-group
                        style="
                          position: relative;
                          overflow-y: auto;
                          max-height: 400px;
                        "
                      >
                        <b-link
                          v-for="(item, index) in SendNotiData"
                          :key="index"
                        >
                          <b-list-group-item
                            class="d-flex align-items-center h-100 noti-custom"
                            :class="{ 'bg-noti': !item.unseenMsgs }"
                            @click="ReadData(item)"
                          >
                            <div
                              v-if="item.fileimg"
                              class="img-noti"
                            >
                              <!-- <img
                                src="/icon/success.svg"
                                alt=""
                                width="10px"
                              /> -->
                              <img
                                :src="item.fileimg"
                                alt=""
                              >
                            </div>

                            <div v-if="!item.fileimg">
                              <img
                                v-if="[2, 3, 4, 5, 6, 16].includes(item.status)"
                                src="/icon/success.svg"
                                alt=""
                                width="40px"
                              >
                              <img
                                v-if="[7, 9, 11, 13, 15].includes(item.status)"
                                src="/icon/fail.svg"
                                alt=""
                                width="40px"
                              >
                            </div>

                            <div class="noti-text m-1 container">
                              <h6 class="font-weight-bolder mb-0">
                                <span class="text">{{ item.message }}</span>
                              </h6>
                              <!-- <small class="text-muted">AnanCargo</small> -->
                            </div>
                          </b-list-group-item>
                        </b-link>
                      </b-list-group>
                    </div>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="dropdown-menu-footer"
                      variant="primary"
                      block
                      @click="Readnotify()"
                    >Read all notifications</b-button>
                  </b-popover>
                </b-link>
              </div>

              <!-- <div class="anan-drawer ZUq1cc">
                <b-link class="flex _2TLLZP ZUq1cc">
                  <span
                    ><i class="fal fa-question-circle" />
                    {{ $t("key-90") }}</span
                  >
                </b-link>
              </div> -->

              <div class="anan-drawer ZUq1cc">
                <b-dropdown
                  no-caret
                  variant="link"
                  size="sm"
                  class="mt-50"
                >
                  <template #button-content>
                    <span class="text-light"><i class="far fa-globe-americas" />
                      {{ selectedLang || "ไทย" }}</span>
                  </template>
                  <b-dropdown-item
                    value="th"
                    @click="updateSelectedText('ไทย', 'th')"
                  ><img
                    src="/thai.png"
                    class="flag-icon"
                  >
                    ไทย</b-dropdown-item>
                  <b-dropdown-item
                    value="en"
                    @click="updateSelectedText('English', 'en')"
                  ><img
                    src="/eng.png"
                    class="flag-icon"
                  >
                    English</b-dropdown-item>
                  <b-dropdown-item
                    value="cn"
                    @click="updateSelectedText('中国', 'cn')"
                  ><img
                    src="/china.png"
                    class="flag-icon"
                  >
                    中国</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="anan-drawer ZUq1cc">
                <b-link
                  :to="{ name: 'profile' }"
                  class="flex _2TLLZP ZUq1cc"
                >
                  <span id="popover-target-2">
                    <!-- <img
                      v-if="UserData.role === 'customer' && UserData.profileimg"
                      :src="UserData.profileimg"
                      width="20"
                    > -->
                    <b-avatar
                      v-if="UserData.role === 'customer' && UserData.profileimg"
                      :src="UserData.profileimg"
                      size="sm"
                    />
                    <b-avatar
                      v-if="UserData.role === 'customer' && !UserData.profileimg"
                      :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')
                      "
                      size="sm"
                    />
                    <!-- <img
                      v-if="UserData.role === 'admin' && UserData.profileimg"
                      :src="UserData.profileimg"
                      width="10"
                    > -->
                    <b-avatar
                      v-if="UserData.role === 'admin' && UserData.profileimg"
                      :src="UserData.profileimg"
                      size="sm"
                    />
                    <b-avatar
                      v-if="UserData.role === 'admin' && !UserData.profileimg"
                      :src="require('@/assets/images/logo/logo-p2.webp')
                      "
                      size="sm"
                    />
                    {{ UserData.username }}
                    <b-popover
                      target="popover-target-2"
                      triggers="hover"
                      placement="bottom"
                      hide-header
                    >
                      <div class="list-profile">
                        <b-link
                          :to="{ name: 'profile' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-user-circle mr5" />
                          </div>
                          <span class="txt-sizas"> {{ $t("key-91") }} </span>
                        </b-link>
                      </div>

                      <div
                        v-if="UserData.ability.some(ele => ele.subject === 'order-list')"
                        class="list-profile"
                      >
                        <b-link
                          :to="{ name: 'order-list' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-shopping-bag" />
                          </div>
                          <span class="txt-sizas"> {{ $t("key-92") }} </span>
                        </b-link>
                      </div>

                      <div
                        v-if="UserData.ability.some(ele => ele.subject === 'refill')"
                        class="list-profile"
                      >
                        <b-link
                          :to="{ name: 'customer-refill' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-wallet" />
                          </div>
                          <span class="txt-sizas"> {{ $t("topup") }} </span>
                        </b-link>
                      </div>

                      <div
                        v-if="UserData.ability.some(ele => ele.subject === 'balance')"
                        class="list-profile"
                      >
                        <b-link
                          :to="{ name: 'balance' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-sack-dollar" />
                          </div>
                          <span class="txt-sizas"> {{ $t("ledger") }} </span>
                        </b-link>
                      </div>

                      <!-- <div class="list-profile" v-if="UserData.ability.some(ele => ele.subject === 'balance')">
                        <b-link :to="{ name: 'balance' }" class="d-flex align-items-center">
                          <div class="icon-linker">
                            <i class="fal fa-sack-dollar" />
                          </div>
                          <span class="txt-sizas"> {{ $t("ledger") }} </span>
                        </b-link>
                      </div> -->

                      <div
                        v-if="UserData.ability.some(ele => ele.subject === 'point')"
                        class="list-profile"
                      >

                        <b-link
                          :to="{ name: 'point' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-gift" />
                          </div>
                          <span class="txt-sizas"> {{ $t("points") }} </span>
                        </b-link>
                      </div>
                      <div class="list-profile border-top">
                        <b-link
                          class="d-flex align-items-center"
                          @click="logout()"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-sign-out" />
                          </div>
                          <span class="txt-sizas"> {{ $t("logout") }} </span>
                        </b-link>
                      </div>
                    </b-popover>
                  </span>
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="anan-set-nav-conttainerx my-1">
        <div class="d-flex justify-content-space-between align-items-center">
          <div class="mr-1 ml-2">
            <router-link :to="{ name: 'new-pages' }">
              <img
                src="@/assets/images/logo/logo-mall-p2.webp"
                alt="logo_anan"
                height="50"
              >
            </router-link>
          </div>

          <div style="width: 930px">
            <!-- <div
              :class="{
                'anan-searchbar': isNotMobile,
                'anan-searchbar-m': !isNotMobile,
              }"
            > -->
            <div class="anan-searchbar">
              <div class="anan-searchbar__main">
                <div class="d-none d-md-block">
                  <a
                    href="https://www.1688.com/"
                    target="_blank"
                    class="mr-50"
                  >
                    <img
                      src="https://portal.plm-cargo.com/1688.png"
                      alt=""
                      height="28"
                    >
                  </a>

                  <a
                    href="https://world.taobao.com/"
                    target="_blank"
                    class="mr-50"
                  >
                    <img
                      src="https://portal.plm-cargo.com/taobao.png"
                      alt=""
                      height="28"
                    >
                  </a>

                  <a
                    href="https://www.tmall.com/"
                    target="_blank"
                    class="mr-50"
                  >
                    <img
                      src="https://portal.plm-cargo.com/tmall.png"
                      alt=""
                      height="28"
                    >
                  </a>
                  <a
                    href="https://www.alibaba.com/"
                    target="_blank"
                    class="mr-50"
                  >
                    <img
                      src="/alibaba.png"
                      alt=""
                      height="28"
                    >
                  </a>
                  <a
                    href="https://m.weidian.com/"
                    target="_blank"
                    class="mr-50"
                  >
                    <img
                      src="https://si.geilicdn.com/img-4037000001898ab8415c0a20e35c-unadjust_158_84.png"
                      alt=""
                      height="28"
                    >
                  </a>
                </div>

                <form
                  role="search"
                  class="anan-searchbar-input"
                >
                  <input
                    v-model="url_link"
                    type="search"
                    class="anan-searchbar-input__input w-100"
                    :placeholder="'Tmall, Taobao, 1688 |' + $t('key-93')"
                    @keyup.enter="GetProductByUrl()"
                  >
                </form>
              </div>
              <button
                type="button"
                class="bttn btn-solid-primary btn--s btn--inline anan-searchbar__search-button"
                @click="GetProductByUrl()"
              >
                <i class="fal fa-search" />
              </button>
            </div>
          </div>

          <div class="anan-cart-icon mr-1 ml-1">
            <b-link :to="{ name: 'cart' }">
              <i
                class="fal fa-shopping-cart text-white"
                style="font-size: 20px"
              />

              <div
                v-if="CartLength > 0"
                class="anan-cart-number-badge"
              >
                {{ CartLength }}
              </div>
            </b-link>
          </div>

          <!-- <div class="d-md-none">
            <SideBar /> -->
          <!-- <b-link :to="{ name: 'profile' }">
              <i
                class="fal fa-regular fa-user text-white"
                style="font-size: 20px"
              />
            </b-link> -->
          <!-- <b-dropdown no-caret variant="link">
              <template #button-content>
                <i
                class="fal fa-regular fa-user text-white"
                style="font-size: 20px"
              />
              </template>
                <b-dropdown-item class="list-profile">
                        <b-link
                          :to="{ name: 'profile' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-user-circle mr5" />
                          </div>
                          <span class="txt-sizas"> {{ $t("key-91") }} </span>
                        </b-link>
                      </b-dropdown-item>

                      <b-dropdown-item class="list-profile">
                        <b-link
                          :to="{ name: 'order-list' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-shopping-bag" />
                          </div>
                          <span class="txt-sizas"> {{ $t("key-92") }} </span>
                        </b-link>
                      </b-dropdown-item>

                      <b-dropdown-item class="list-profile">
                        <b-link
                          :to="{ name: 'customer-refill' }"
                          class="d-flex align-items-center"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-wallet" />
                          </div>
                          <span class="txt-sizas"> {{ $t("topup") }} </span>
                        </b-link>
                      </b-dropdown-item>

                      <b-dropdown-item class="list-profile">
                        <b-link :to="{name: 'profile-address'}" class="d-flex align-items-center">
                          <div class="icon-linker">
                            <i class="fal fa-sack-dollar" />
                          </div>
                          <span class="txt-sizas"> {{ $t("ledger") }} </span>
                        </b-link>
                      </b-dropdown-item>

                      <b-dropdown-item class="list-profile border-top">
                        <b-link
                          class="d-flex align-items-center"
                          @click="logout()"
                        >
                          <div class="icon-linker">
                            <i class="fal fa-sign-out" />
                          </div>
                          <span class="txt-sizas"> {{ $t("logout") }} </span>
                        </b-link>
                      </b-dropdown-item> -->
          <!-- <b-dropdown-item>Action 1</b-dropdown-item>
              <b-dropdown-item>Action 2</b-dropdown-item>
              <b-dropdown-item><span class="txt-sizas"> {{ $t("logout") }} </span></b-dropdown-item> -->
          <!-- </b-dropdown> -->
          <!-- </div> -->
        </div>
      </div>

      <div class="anan-set-nav-conttainer d-none d-md-block">
        <div class="flex justify-content-center">
          <div class="anan-drawer ZUq1cc">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'order-list')"
              :to="{ name: 'order-list' }"
              class="flex _2TLLZP"
            >
              <span><i class="fal fa-shopping-cart" /> {{ $t("orderList") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'tracking')"
              :to="{ name: 'tracking' }"
              class="flex _2TLLZP"
            >
              <span><i class="fal fa-barcode" /> {{ $t("parcelCode") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'checkbill')"
              :to="{ name: 'payment-create' }"
              class="flex _2TLLZP"
            >
              <span><i class="fal fa-box-check" /> {{ $t("checkAndNotify") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'exportproducts')"
              :to="{ name: 'export' }"
              class="flex _2TLLZP"
            >
              <span><i class="fal fa-box-full" /> {{ $t("exportList") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'goodsconfirm')"
              :to="{ name: 'goodsconfirm' }"
              class="flex _2TLLZP"
            >
              <span><i class="fal fa-dolly-flatbed-alt" />
                {{ $t("ownerlessGoods") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'lost_package')"
              :to="{ name: 'lost-package' }"
              class="flex _2TLLZP"
            >
              <span><i class="fas fa-bell-exclamation" />
                {{ $t("reportMissingGoods") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'refill')"
              :to="{ name: 'customer-refill' }"
              class="flex _2TLLZP"
            >
              <span><i class="fal fa-sack-dollar" /> {{ $t("topupSystem") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'balance')"
              :to="{ name: 'balance' }"
              class="flex _2TLLZP"
            >
              <span><i class="fal fa-wallet" /> {{ $t("ledger") }}</span>
            </b-link>
          </div>
          <div class="anan-drawer ZUq1cc2">
            <b-link
              v-if="UserData.ability.some(ele => ele.subject === 'notify')"
              :to="{ name: 'notice' }"
              class="flex _2TLLZP"
            >
              <span
                v-if="notifycount"
                class="notifys-badge2 pulse"
              >
                {{ notifycount }}
              </span>
              <span><i class="fal fa-bullhorn" />
                {{ $t("announcement") }}

              </span>

            </b-link>
          </div>
        </div>
      </div>
    </header>
    <!-- <chat/> -->
  </div>
</template>

<script>
import {
  BAvatar,
  BPopover,
  BLink,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BNavItemDropdown,
  BNavbarNav,
  BDropdownDivider,
  BButton,
  BBadge,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-extraneous-dependencies
import Ws from '@adonisjs/websocket-client'
// import chat from '@/views/customer/chat/chat.vue'
import SideBar from '../profile/component/SideBar.vue'

export default {
  name: 'CustomerNewView',
  components: {
    BAvatar,
    BPopover,
    BLink,
    BFormRadio,
    BDropdown,
    BDropdownItem,
    SideBar,
    BNavItemDropdown,
    BNavbarNav,
    BDropdownDivider,
    BButton,
    BBadge,
    BListGroup,
    BListGroupItem,
    // chat,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      selectedLang: null,
      url_link: '',
      CartLength: 0,
      loading: true,
      UserData: JSON.parse(localStorage.getItem('userData')),
      SendNotiData: [],
      SendNoticount: 0,
      SendNoticountall: 0,
      adminAbilities: null,
      notifycount: 0,
    }
  },
  // watch: {
  //   locale(val) {
  //     // this.$i18n.locale = val;
  //     console.log(val);
  //   },
  // },
  computed: {
    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsMain() {
      return this.$store.state.app.WsMain
    },
    CartWs() {
      return this.$store.state.app.WsCart
    },
    SendNotiWs() {
      return this.$store.state.app.WsSendNotify
    },
    WsNotifycount() {
      return this.$store.state.app.WsNotifycount
    },
  },
  created() {
    // this.interval = setInterval(() => {
    //   // this.getUserslist()
    //   this.GetMycart()
    // }, 5000)
  },
  mounted() {
    this.getPreProduct()
    // this.GetMycart()
    this.CheckWsConnect()
    this.Getsendnotify()
    this.onShow = localStorage.getItem('token') !== null
  },
  methods: {
    updateSelectedText(text, lang) {
      this.selectedLang = text
      this.$i18n.locale = lang
    },
    async CheckWsConnect() {
      if (!this.WsMain) {
        await this.ConnectWS()
      }
      if (!this.CartWs) {
        await this.SubWsOnCart()
        this.WsOnCart()
      } else {
        this.WsOnCart()
      }
      if (!this.SendNotiWs) {
        await this.SubWsOnSendNoti()
        this.WsOnSendNoti()
      } else {
        this.WsOnSendNoti()
      }
      if (!this.WsNotifycount) {
        await this.SubWsOncountNoti()
        this.WsOnNoticount()
      } else {
        this.WsOnNoticount()
      }
    },
    ConnectWS() {
      const Token = localStorage.getItem('token')
      if (Token) {
        // const ws = Ws('wss://api.p2cargo.com')
        const ws = Ws(this.BaseWsUrl)
        ws.withJwtToken(Token).connect()

        ws.on('open', () => {
          console.log('Connection established')
        })
        ws.on('error', error => {
          console.log('Connection Error', error)
        })

        ws.on('close', event => {
          console.log('Connection closed', event)
        })
        this.$store.commit('app/SET_WSMAIN', ws)
      }
    },
    SubWsOnCart() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsCart = this.WsMain.subscribe(`cart:${UserData.userID}`)
      this.$store.commit('app/SET_WSCART', WsCart)
    },
    SubWsOnSendNoti() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsCart = this.WsMain.subscribe(`SendNotify:${UserData.userID}`)
      this.$store.commit('app/SET_WSSENDNOTIFY', WsCart)
    },
    SubWsOncountNoti() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsCount = this.WsMain.subscribe(`NotifyCount:${UserData.userID}`)
      this.$store.commit('app/SET_WSNOTIFYCOUNT', WsCount)
    },
    WsOnNoticount() {
      this.WsNotifycount.on('open', () => {
        console.log('CartWs established')
      })
      this.WsNotifycount.emit('message', ' ')
      this.WsNotifycount.on('message', message => {
        this.notifycount = message
      })
      this.WsNotifycount.on('error', error => {
        console.log('Cart Error', error)
      })
    },
    WsOnCart() {
      this.CartWs.on('open', () => {
        console.log('CartWs established')
      })
      this.CartWs.emit('cart', '')
      this.CartWs.on('cart', message => {
        this.CartLength = message
      })
      this.CartWs.on('error', error => {
        console.log('Cart Error', error)
      })
    },
    WsOnSendNoti() {
      this.SendNotiWs.on('open', () => {
        console.log('CartWs established')
      })
      this.SendNotiWs.emit('message', '')
      this.SendNotiWs.on('message', message => {
        if (message.message) {
          this.SendNotiData.unshift(message)
          this.SendNoticount = this.SendNotiData.reduce(
            (total, ele) => total + (ele.unseenMsgs === false ? 1 : 0),
            0,
          )
        }
        if (message.count) {
          this.SendNoticount = message.count
        }
        this.SendNoticountall = this.SendNotiData.length
      })
      this.SendNotiWs.on('error', error => {
        console.log('Cart Error', error)
      })
    },
    GetMycart() {
      this.$http
        .get('/cart/mycart')
        .then(response => {
          // console.log(response.data)
          this.items = response.data
          const Shop = response.data.shops
          this.CartLength = Shop.reduce(
            (acc, ele) => acc + ele.products.length,
            0,
          )
          // this.CartLength = response.data.length
        })
        .catch(error => console.log(error))
    },
    Readnotify() {
      this.$http
        .post('/Send/ReadNotify')
        .then(() => {
          this.Getsendnotify()
          this.SendNoticount = 0
        })
        .catch(err => console.log(err))
    },
    Getsendnotify() {
      this.$http
        .get('/Send/NotifyCus')
        .then(response => {
          this.SendNotiData = response.data.data
          this.SendNoticountall = response.data.total
        })
        .catch(err => console.log(err))
    },
    ReadData(item) {
      const obj = {
        id: item._id,
      }
      this.$http.post('/Send/ReadNotifyOne', obj)
        .then(() => {
          this.Getsendnotify()
          if (this.SendNoticount) {
            this.SendNoticount = Number(this.SendNoticount) - 1
          }
          if (item.path_name) {
            this.$router.push({ name: `${item.path_name}`, params: { id: item.ref } })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPreProduct() {
      this.$http
        .get('/preproduct/list')
        .then(res => {
          this.info = res.data.data
          // console.log(this.info)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async GetProductByUrl() {
      const obj = {
        provider: '',
        id: '',
        cache: false,
      }
      const urlRegex = /(https?:\/\/[^\s]+)/g
      const urls = this.url_link.match(urlRegex)
      const url = new URL(urls[0])
      const {
        host, pathname, search, href,
      } = url
      if (host && host.length > 0) {
        const index1688 = host.indexOf('1688')
        const indexqr = host.indexOf('qr.1688.com')
        const indextaobao = host.indexOf('taobao')
        const indextmall = host.indexOf('tmall')
        const indextmalls = host.indexOf('m.tb.cn')
        const indexalibaba = host.indexOf('alibaba')
        const indexmarket = host.indexOf('market.m.taobao')
        const indexweidian = host.indexOf('weidian')
        if (indexqr > -1) {
          obj.provider = '1688'
          obj.id = pathname
        } else if (index1688 > -1) {
          // console.debug(host)
          obj.provider = '1688'
          if (host === 'mindx.1688.com' || host === 'detail.m.1688.com') {
            obj.id = url.searchParams.get('offerId')
          } else {
            const IndexCheck = pathname.indexOf('/offer/')
            if (IndexCheck > -1) {
              const of = new RegExp('/offer/', 'g')
              const ht = new RegExp('.html', 'g')
              obj.id = pathname.replace(of, '')
              obj.id = obj.id.replace(ht, '')
            }
          }
        } else if (indexmarket > -1) {
          obj.provider = 'market'
          const IndexCheck = search.indexOf('itemIds')

          if (IndexCheck > -1) {
            obj.id = url.searchParams.get('itemIds')
          } else {
            obj.id = url.searchParams.get('id')
          }
        } else if (indextaobao > -1) {
          obj.provider = 'taobao'
          const IndexCheck = search.indexOf('itemIds')
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get('itemIds')
          } else {
            obj.id = url.searchParams.get('id')
          }
        } else if (indextmall > -1) {
          obj.provider = 'tmall'
          obj.id = url.searchParams.get('id')
        } else if (indextmalls > -1) {
          const ht = pathname.replace('/', '')
          obj.provider = 'tmall'
          obj.id = ht
          obj.cache = true
        } else if (indexalibaba > -1) {
          obj.provider = 'alibaba'
          const IndexCheck = pathname.indexOf('/product-detail/')
          if (IndexCheck > -1) {
            const of = new RegExp('/product-detail/', 'g')
            const ht = new RegExp('.html', 'g')
            obj.id = pathname.replace(of, '')
            obj.id = obj.id.replace(ht, '')
          } else {
            const of = new RegExp('/product-detail/', 'g')
            const ht = new RegExp('.html', 'g')
            obj.id = pathname.replace(of, '')
            obj.id = obj.id.replace(ht, '')
          }
        } else if (indexweidian > -1) {
          obj.provider = 'weidian'
          const IndexCheck = search.indexOf('itemID')
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get('itemID')
          } else {
            obj.id = url.searchParams.get('id')
          }
        }
        if (obj.provider && obj.id) {
          if (this.$route.name !== `product2-${obj.provider}`) {
            this.$router.push({
              name: `product2-${obj.provider}`,
              params: { id: obj.id, cache: obj.cache },
            })
          } else {
            this.$router.push({
              name: `product2-${obj.provider}`,
              params: { id: (obj.id), cache: obj.cache },
            })
            window.location.reload()

            // this.Alert(false, "test", "test");
          }
        } else {
          this.Alert(
            false,
            'ไม่พบข้อมูลสินค้า',
            'กรุณากรอก URL ใหม่ให้ถูกต้อง',
          )
        }
      }
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
      this.WsMain.close()
      this.$store.commit('app/SET_WSMAIN', null)
      this.$store.commit('app/SET_WSCART', null)
      this.$store.commit('app/SET_WSCHAT', null)
      this.$store.commit('app/SET_WSSENDNOTIFY', null)
      this.$store.commit('app/SET_WSRUNTRACK', null)
      this.$store.commit('app/SET_WSNOTIFYCOUNT', null)
      this.onShow = localStorage.getItem('token') !== null
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-as-text {
  background-color: transparent;
  border: none;
}

.flag-icon {
  width: 20px;
  /* หรือขนาดที่ต้องการ */
  height: auto;
  margin-right: 5px;
}

.bg-noti {
  background-color: rgb(0, 0, 0, 0.2);
}

.noti-custom {
  height: 100%;
  max-height: 100px;
}

.text {
  overflow: hidden;
  /* ซ่อนข้อความที่เกิน */
  text-overflow: ellipsis;
  /* แทนที่ข้อความที่เกินด้วย ... */
}

.container {
  max-height: 50px;
  /* กำหนดความสูงสูงสุดของคอนเทนเนอร์ */
  overflow: hidden;
  /* ซ่อนข้อความที่เกินความสูงสูงสุด */
}

.notifys-badge2{
  font-family: "Poppins", sans-serif;
    position: absolute;
    left:10px;
    top: -1px;
    min-width: 1rem;
    min-height: 1rem;
    background:#FF3838;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color:white;
    padding: 1px;
    font-size: 0.786rem;
    line-height: 0.786;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
